<template>
  <vx-card style="padding-left: 10px">
    <!-- <vs-label style="font-weight: bold"> -->

    <!-- </vs-label> -->
    <div id="data-list-list-view" class="data-list-container">
      <vs-table
        ref="table"
        :sst="true"
        :total="totalDocs"
        :data="users"
        @search="handleSearch"
        @change-page="handleChangePage"
        @sort="handleSort"
        :max-items="dataTableParams.limit"
        search
      >
       <div slot="header" class="flex flex-wrap-reverse items-center flex-grow justify-between">
        
         <div>
             <vs-button class="mb-4 md:mb-0" @click="$router.push(`/admin/postcode/create`)">Create</vs-button>
            <input type="file" class="hidden" ref="select_tips_csv" @change="importTipFromCSV" accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" >
            <vs-button class="mx-3 mb-4 md:mb-0" color="warning" @click="loadImportForm" >Import CSV</vs-button>
          </div>
          
          <div class="flex flex-wrap-reverse items-center data-list-btn-container"></div>
          <v-select
            v-model="dataTableParams.limit"
            class="mr-4"
            :options="limitOptions"
            :clearable="false"
          />
        </div>
        
        <template slot="thead">
          <vs-th sort-key="name">Suburb</vs-th>
          <vs-th sort-key="email">State Code</vs-th>
          <vs-th sort-key="lastName">Postal Code</vs-th> 
          <vs-th sort-key="lastName">Latitude</vs-th>
          <vs-th sort-key="status">Longitude</vs-th>
          <vs-th sort-key="action" size="25%">Actions</vs-th>

        </template>
        

        <template slot-scope="{data}">
          <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
            <vs-td :data="data[indextr].name">{{ data[indextr].name?data[indextr].name:'' }}</vs-td>
            <vs-td :data="data[indextr].email">{{ data[indextr].stateCode }}</vs-td>
            <vs-td :data="data[indextr].lastName">{{ data[indextr].postalCode}}</vs-td>
         
            <vs-td :data="data[indextr].autoRenewStatus">{{ data[indextr].latitude }}</vs-td>
            <vs-td :data="data[indextr].autoRenewStatus">{{ data[indextr].longitude }}</vs-td> 
            <vs-td :data="data[indextr]._id">
              <vs-row vs-type="flex">
                <!--<vx-tooltip text="View" position="top" delay=".3s">-->
                <!--  <feather-icon-->
                <!--    icon="EyeIcon"-->
                <!--    svgClasses="h-5 w-5 mr-4 hover:text-primary cursor-pointer"-->
                <!--  />-->
                <!--</vx-tooltip>-->
                <vx-tooltip text="Edit" position="top" delay=".3s">
                  <feather-icon
                    icon="Edit3Icon"
                    svgClasses="h-5 w-5 mr-4 hover:text-primary cursor-pointer"
                    @click="$router.push(`/admin/postcode/edit/${data[indextr]._id}`).catch(() => {})"
                  />
                </vx-tooltip>
                <vx-tooltip text="Delete" position="top" delay=".3s">
                  <feather-icon
                    icon="Trash2Icon"
                    svgClasses="h-5 w-5 hover:text-danger cursor-pointer"
                    @click="openDeleteModal(data[indextr]._id)"
                  />
                </vx-tooltip>
              </vs-row>
              <!-- <vs-button type="border" size="small" icon-pack="feather" icon="icon-send" color="success" class="mr-2"></vs-button> -->
            </vs-td>
          </vs-tr>
        </template>
      </vs-table>
      <span
        class="mr-2"
      >{{ currentPage * dataTableParams.limit - (dataTableParams.limit - 1) }} - {{ totalDocs - currentPage * dataTableParams.limit > 0 ? currentPage * dataTableParams.limit : totalDocs }} of {{ totalDocs }}</span>
      <vs-pagination :total="totalPage" v-model="dataTableParams.page"></vs-pagination>
    </div>
  </vx-card>
</template>

<script>
import vSelect from "vue-select";
import { mapActions } from "vuex";
import moment from "moment";
 import axios from "../../axios.js"
export default {
  components: {
    "v-select": vSelect,
  },
  data() {
    return {
      isMounted: false,
      totalDocs: 0,
      currentPage: 1,
      dataTableParams: {
        search: "",
        sort: "createdAt",
        dir: "desc",
        page: 1,
        limit: 10,
      },
      users: [],
      limitOptions: [5, 10, 25, 50, 100],
    };
  },
  methods: {
    ...mapActions("postalCode", ["fetchPostalCodes","deletePostalCode"]),
    moment(date) {
      return moment(date);
    },
    getPostCodes() {
      let self = this;
      this.fetchPostalCodes(self.dataTableParams).then((res) => {
        self.users = res.data.data.docs;
        self.totalDocs = res.data.data.pagination.total;
        self.currentPage =res.data.data.pagination.page;
        self.page = res.data.data.pagination.page;

      });
    },
    handleSearch(searching) {
      this.dataTableParams.search = searching;
      this.dataTableParams.page = 1;
      this.$refs.table.currentx = 1;
      this.getPostCodes();
    },
    handleChangePage(page) {
      this.dataTableParams.page = page;
      this.getPostCodes();
    },
    handleSort(key, active) {
      this.dataTableParams.sort = key;
      this.dataTableParams.dir = active;
      this.dataTableParams.page = 1;
      this.$refs.table.currentx = 1;
      this.getPostCodes();
    },
    viewDetailHandler(id) {
      this.$router.push({ name: "subscriptionDetail", params: { id: id } });
    },
    loadImportForm(){
      this.tipsCSVFile = null
      this.csvIsValid = false
      this.$refs.select_tips_csv.click()
      //this.csvErrorFlag = false
      //this.showImportCSVpopUp = true
    },
    async importTipFromCSV(e){
      e.preventDefault();
      let selectedFile = (e.target.files[0]) ? e.target.files[0] : null;

      if(selectedFile){

        let fileExt = selectedFile.name.split('.').pop();
        if (fileExt != 'csv') {
            console.log('large size')

          this.$vs.notify({color:'warning',title:'Import Failed',text:this.csvErrorMsg})
          return
        } else {
    
          const formData = new FormData();
          let config = {
            header: {
              'Content-Type': 'multipart/form-data'
            }
          };

          formData.append('csv', selectedFile);
          this.$vs.loading();
          axios
            .post("admin/postalCode/import", formData)
            .then(res => {
              this.getPostCodes();
              this.$vs.loading.close();
              this.$vs.notify({color:'success',title:'Import Success',text:'PostalCode imported successfully.'})
            
            })
            .catch(err => {
              console.log('tips csv Import error : \n',err)
              this.$vs.notify({color:'error',title:'Import Failed',text:'Error importing postalcode.'})
            }); 
        }
      } else {
        //this.csvErrorFlag=true
       // this.$vs.notify({color:'warning',title:'Import Failed',text:this.csvErrorMsg})
        return
      }
    },
      openDeleteModal(id) {
      this.$vs.dialog({
        type: 'confirm',
        color: 'danger',
        title: `Confirm`,
        text: "Are you sure? You can't undo it.",
        accept: this.acceptDeletePostCode,
        parameters: [id]
      })
    },
    acceptDeletePostCode(parameters) {
      this.deletePostalCode(parameters[0]).then(response => {
          if(response.status === 200) {
            this.getPostCodes();
          this.$vs.notify({
        color: 'danger',
        title: response.data.title,
        text: response.data.message
      })
          }
      }).catch(error => {
        this.showDismissibleAlert=true
                this.message=error.message
                this.$vs.notify({
                  title: 'Error',
                  text: error.message,
                  iconPack: 'feather',
                  icon: 'icon-alert-circle',
                  color: 'danger'
                });
      })
    },


  
  },
  computed: {
    totalPage: function () {
      return this.totalDocs / this.dataTableParams.limit >
        parseInt(this.totalDocs / this.dataTableParams.limit)
        ? parseInt(this.totalDocs / this.dataTableParams.limit) + 1
        : parseInt(this.totalDocs / this.dataTableParams.limit);
    },
  },
  watch: {
    "dataTableParams.page": function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.dataTableParams.page = newVal;
        this.getPostCodes();
      }
    },
    "dataTableParams.limit": function (newlimit, oldLimit) {
      if (newlimit !== oldLimit) {
        this.dataTableParams.page = 1;
        this.dataTableParams.limit = newlimit;
        this.getPostCodes();
      }
    },
  },
  mounted() {
    this.getPostCodes();
  },
};
</script>
